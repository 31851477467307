<template>
  <v-theme-provider dark>
    <section id="hero-alt">
      <base-img
        :height="$vuetify.breakpoint.mdAndUp ? 350 : 225"
        :gradient="gradient"
        :src="src"
        color="rgba(24, 61, 114, .9)"
        flat
        max-width="100%"
        tile
      >
        <v-row
          v-if="title"
          align="center"
          class="ma-0 fill-height text-center"
          justify="center"
        >
          <v-col cols="12">
            <base-heading
              :title="title"
              space="2"
            />

            <base-divider
              color="primary"
              dense
            />

          </v-col>
        </v-row>
      </base-img>
    </section>
  </v-theme-provider>
</template>

<script>
  // Components
  import {
    //HexToRGBA,
    //RGBAtoCSS,
  } from 'vuetify/lib/util/colorUtils'

  export default {
    name: 'SectionHeroAlt',

    metaInfo () {
      return {
        changed: meta => (this.title = meta.titleChunk.toUpperCase()),
      }
    },

    provide: {
      heading: { align: 'center' },
    },

    props: {
      imageSrc: String,
    },

    data: () => ({
      title: '',
    }),

    computed: {
      gradient () {
        // const color = `${this.$vuetify.theme.themes.light.primary}CC`
        // const overlay = RGBAtoCSS(HexToRGBA(color))
        // return `to right, ${overlay}, ${overlay}`
        return `to left, rgba(0, 0, 0, .8), rgba(24, 61, 114, .9)`;
      },
      src () {
        return this.imageSrc || this.$route.meta.src
      },
    },
  }
</script>

<style lang="sass">
  #hero-alt
    .v-breadcrumbs__item
      color: #FFFFFF
</style>
